<template>
  <div v-if="value.orderStatus === '2'">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <img src="/img/loader.gif" alt="loader" class="mr-sm-2"
             v-bind="attrs"
             v-on="on">
      </template>
      <span :style="`color: ${statusColor(value.orderStatus)}`">Выполняется</span>
    </v-tooltip>
    <span v-if="value.exec_time">{{ $moment.utc(value.exec_time * 1000).format('HH:mm') }}</span>
  </div>
  <div :style="`color: ${statusColor(value.orderStatus)}`" v-else>{{ value.orderStatusString }}</div>
</template>
<script>

export default {
  name: "OrderStatus",
  props: {
    value: {},
  },
  methods: {
    statusColor(status) {
      let colors = ["red", "green", "violine", "#3000ff", "black", "green", "#ff0404", "#7420ff", "#e50083"];
      return colors[Number(status)] || 'black';
    },
  }
}
</script>