<template>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title class="pb-0" style="justify-content: space-between">
          Реклама
        </v-card-title>
        <DatePeriod @change="reloadTable" v-model="date"></DatePeriod>
        <v-card-text class="pt-0">
          <v-data-table
              hover
              :items="rows"
              :headers="headers"
              item-key="id"
              sort-by="stats.total.cost"
              sort-desc
              :loading="loadingTable"
              :footer-props="{'items-per-page-options':[-1]}"
              no-data-text="Данных нет"
              loading-text="Загрузка..."
              mobile-breakpoint="0"
          >
            <template v-slot:item="{item, index}">
              <AnalyticsRow @click.native="loadInstrumentOrders(item.id, '', '')"
                            :row="item.stats.total" :instrument="item.city" :opened="filter.city===item.id"/>
              <template v-for="(row, instrument) in item.stats.instruments" v-if="item.id===filter.city">
                <AnalyticsRow :style="'background:#eee'" @click.native="loadInstrumentOrders(item.id, instrument, '')"
                              :row="row" :instrument="instrument" :opened="filter.instrument===instrument"/>
                <template v-if="filter.instrument===instrument" v-for="(campaign, campaignName) in row.campaigns">
                  <AnalyticsRow padding="20" :style="'background:#ddd'"
                                @click.native="loadInstrumentOrders(item.id, instrument, campaignName)" :row="campaign"
                                :instrument="campaignName"
                                :opened="filter.campaign===campaignName"/>
                  <AnalyticsOrdersTable :title="`Заказы по <span style='color: #1976d2'>${campaignName}</span>`"
                                        v-if="filter.campaign===campaignName && !filter.group" :orders="analyticsOrders"/>
                </template>
              </template>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>

import DatePeriod from "../../components/DatePeriod";
import AnalyticsRow from "../../components/AnalyticsRow";
import AnalyticsOrdersTable from "../../components/AnalyticsOrdersTable";

export default {
  name: "Salary",
  components: {AnalyticsOrdersTable, AnalyticsRow, DatePeriod},
  data() {
    let today = this.$moment().format('YYYY-MM-DD');
    return {
      rows: [],
      analyticsOrders: [],
      // openedCampaign: '',
      // openedInstrument: '',
      // openedCity: '',
      total: {},
      date: [this.$moment().startOf('month').format('YYYY-MM-DD'), today],
      loadingTable: true,
      filter: {},
      headers: [
        {text: 'Регион', value: 'city.name'},
        {text: 'Показы', value: 'stats.total.views'},
        {text: 'Клики', value: 'stats.total.clicks'},
        {text: 'CPC', value: 'stats.total.cpc'},
        {text: 'CTR', value: 'stats.total.CTR'},
        {text: 'Расход', value: 'stats.total.cost'},
        {text: 'Заказов', value: 'stats.total.orders_count_total'},
        {text: 'CV', value: 'stats.total.cv'},
        {text: 'CPL', value: 'stats.total.cpl'},
        {text: 'Не отмененных заказов', value: 'stats.total.orders_count'},
        {text: 'Отмененных заказов', value: 'stats.total.orders_count_canceled'},
        {text: 'CPO', value: 'stats.total.cpo'},
        {text: 'ROAS', value: 'stats.total.roas'},
        {text: 'ДРР', value: 'stats.total.drr'},
      ]
    }
  },
  methods: {
    percent(a, b, diff = false) {
      let res = parseInt(a / b * 100) || 0;
      if (res && diff) res = res - 100;
      let color = '';
      if (res < 0) {
        color = 'red';
      } else if (res > 0) {
        color = 'green';
        res = '+' + res;
      }
      return `<span style="color: ${color}">${res}%</span>`;
    },
    reloadTable() {
      this.rows = [];
      this.total = {};
      this.loadingTable = true;
      this.$store.state.server.request('regions/analytics', {date: this.date}, (data) => {
        this.rows = data.response;
        this.loadingTable = false;
      }, () => {
        this.loadingTable = false
      })
    },
    loadInstrumentOrders(city, instrument, campaign, group, ad, term) {
      let params = {city, instrument, campaign, group, ad, term};
      let fields = [];
      let opened = 0;
      let j = 0;
      for (let i in params) {
        if (params[i]) {
          opened = j;
        }
        j++;
        fields.push(i);
      }
      let openedField = fields[opened];
      if (this.filter[openedField] === params[openedField]) {
        params[openedField] = '';
        this.analyticsOrders = [];
        this.filter = {...params};
        return;
        //closing
      } else {
        this.analyticsOrders = [{}];
      }
      this.filter = {...params};
      this.filter.date = this.date;
      console.log(this.filter);
      // console.log('loadInstrumentOrders', {instrument, campaign});
      // this.openedCity = this.openedCity === city && !campaign && !instrument ? '' : city
      // this.openedInstrument = this.openedInstrument === instrument && !campaign ? '' : instrument
      // this.openedCampaign = this.openedCampaign === campaign ? '' : campaign
      // if (!this.openedCampaign) {
      //   this.analyticsOrders = [];
      //   return;
      // } else {
      //   this.analyticsOrders = [{}]
      // }
      this.$store.state.server.request('regions/analyticsOrders', this.filter, (data) => {
        this.analyticsOrders = data.response;
      });
    },
  },
  mounted() {
    this.reloadTable()
  }
}
</script>