<template>
  <div v-if="value && value.street">
    <span v-if="value.metro" :style="`color: #${value.metroColor}`">
      (м.{{value.metro }})
    </span>
    {{ value.street }}, {{value.house }}
  </div>
</template>
<script>

export default {
  name: "OrderAddress",
  props: {
    value: {},
  },
}
</script>