<template>
  <tr>
    <td colspan="13">
      <v-simple-table>
        <thead>
        <tr>
          <th colspan="9" v-html="title"></th>
        </tr>
        </thead>
        <tbody>
        <template v-if="orders.length">
          <tr v-if="orders[0].n_id" v-for="order in orders"
              :style="parseInt(order.orderStatus)===4?'background: #ffe9e9':''">
            <td>
              <IconTip v-if="[0,3,8].includes(+order.createdIn)" color="green" icon="phone-incoming" title="Оформлен по звонку"/>
<!--                                      {{order.get.utm_campaign}}<br>-->
<!--                                      {{order.get.utm_term}}-->
            </td>
            <td>
              <ClientLink :value="order.client"></ClientLink>
            </td>
            <td>
              <router-link :to="'/orders/'+order.n_id">
                {{ order.comment }}
              </router-link>
            </td>
            <td :style="['background: #ffe7fb', 'background: #caf3ff'][order.colorType]">
              <router-link :to="'/orders/'+order.n_id">
                {{ $root.dateToRus(order.datetimecleanObj.date) }} {{ order.datetimecleanObj.time }}
              </router-link>
            </td>
            <td>
              <router-link :to="'/orders/'+order.n_id">
                {{ $root.dateToRus(order.datetime_createObj.date) }} {{ order.datetime_createObj.time }}
              </router-link>
            </td>
            <td style="white-space: normal;">
              <router-link :to="'/orders/'+order.n_id">
                <OrderAddress :value="order.address"></OrderAddress>
              </router-link>
            </td>
            <td>
              <OrderStatus :value="order"></OrderStatus>
            </td>
            <td>
              <div>{{ order.manager.fname }}</div>
            </td>
            <td style="white-space: nowrap">
              {{ $root.printCost(order.total) }}
            </td>
            <td>
              <div>{{ order.promo }}
                {{
                  parseInt(order.discountPromo) ? order.discountPromo + $store.state.user.city.country.currency : parseInt(order.discount) ? order.discount + '%' : ''
                }}
              </div>
            </td>
            <td style="white-space: nowrap">
              {{ $root.printCost(order.taxi) }}
            </td>
          </tr>
          <tr v-else-if="!orders[0].n_id">
            <v-btn style="background: transparent !important;" loading></v-btn>
          </tr>
        </template>
        <tr v-else>
          <td>Заказов нет</td>
        </tr>
        </tbody>
      </v-simple-table>
    </td>
  </tr>
</template>
<script>
import OrdersSum from "./OrdersSum";
import ClientLink from "./ClientLink";
import OrderAddress from "./OrderAddress";
import OrderStatus from "./OrderStatus";
import Table from "../views/feedback/Table";
import IconTip from "./IconTip";

export default {
  name: "AnalyticsOrdersTable",
  components: {IconTip, Table, OrderStatus, OrderAddress, ClientLink, OrdersSum},
  props: ['orders', 'title'],
}
</script>
