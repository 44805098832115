<template>
  <tr style="cursor: pointer" :style="opened ? 'background:#aef2f6':''">
    <td style="text-transform: capitalize;white-space: nowrap" :style="padding ? 'padding-left: '+padding+'px' : ''">
      <v-icon>{{ opened ? 'mdi-menu-down' : 'mdi-menu-right' }}</v-icon>
      {{ instrument }}
      <template v-if="row.balance">
        (<span :style="parseInt(row.balance)<(instrument==='facebook' ? -3000 : 1500) ? 'color: red': ''">{{
          $root.printCost(row.balance)
        }}</span>)
      </template>
      <template v-else-if="instrument==='google'">
        (---
        <InfoTip value="Google не даёт информацию о балансе по API"></InfoTip>
        )
      </template>
      <v-icon v-if="instrument==='google' && $store.state.user.city.id==1"
              @click.stop="googleAuth.open()" :color="row.error ? 'red':''" size="16">
        mdi-cog-outline
      </v-icon>
      <v-icon v-if="instrument==='yandex'" @click.stop="yandexAuth.open()"
              :color="row.error ? 'red':''" size="16">mdi-cog-outline
      </v-icon>
      <v-icon v-if="instrument==='vk1'" @click.stop="vkAuth.open()"
              :color="row.error ? 'red':''" size="16">mdi-cog-outline
      </v-icon>
      <v-icon v-if="instrument==='facebook'" @click.stop="facebookAuth.open()"
              :color="row.error ? 'red':''" size="16">mdi-cog-outline
      </v-icon>
    </td>
    <td>{{ row.views }}</td>
    <td>{{ row.clicks }}</td>
    <td>{{ $root.printCost(row.cost / row.clicks) }}</td>
    <td>{{ row.views * 1 ? parseInt(row.clicks * 100 / row.views) : '---' }}%</td>
    <td style="white-space: nowrap">{{ $root.printCost(row.cost) }}</td>
    <td>{{ (row.orders_count * 1 + row.orders_count_canceled * 1) || 0 }} <InfoTip :text="`(${row.orders_count_from_web*1||0})`" value="Оформлено не в CRM"/></td>
    <td>
      {{
        row.clicks ? ((row.orders_count * 1 + row.orders_count_canceled * 1) * 100 / row.clicks).toFixed(1) + '%' : '---'
      }}
    </td>
    <td style="white-space: nowrap">
      {{ $root.printCost(row.cost / (row.orders_count * 1 + row.orders_count_canceled * 1)) }}
      <span style="color: grey;font-size: 13px;display: inline-block;vertical-align: middle">{{
          row.orders_total ? (row.cost / (row.orders_count * 1 + row.orders_count_canceled * 1) / (row.orders_total / row.orders_count) * 100).toFixed(1) + '%' : ''
        }}</span>
    </td>
    <td>
      <OrdersSum :sum="row.orders_total" :count="row.orders_count"></OrdersSum>
      <v-tooltip v-if="row.orders && row.orders.types" top>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <div>
              <span style="white-space: nowrap;color: #3399ff"
                    v-for="(title, type) in $store.state.cleanTypes">{{ $root.printCost(row.orders.types[type] ? row.orders.types[type].total : 0) }} {{ type !== 'other' ? '/' : '' }} </span>
            </div>
            <div>
              <span style="white-space: nowrap;color: #3399ff"
                    v-for="(title, type) in $store.state.cleanTypes">{{ row.orders.types[type] ? row.orders.types[type].count : 0 }} шт {{ type !== 'other' ? '/' : '' }} </span>
            </div>
          </div>
        </template>
        <span>Стандартные / Генеральные / После ремонт / Другое</span>
      </v-tooltip>
    </td>
    <td>
      <OrdersSum :sum="row.orders_total_canceled" :count="row.orders_count_canceled"></OrdersSum>
    </td>
    <td style="white-space: nowrap">{{ $root.printCost(row.cost / row.orders_count) }}</td>
    <td>{{ +row.cost ? parseInt(row.orders_total / row.cost * 100) : '---' }}%</td>
    <td>{{ +row.orders_total ? Math.round(row.cost/row.orders_total*100) : '---'}}%</td>
  </tr>
</template>
<script>
import OrdersSum from "./OrdersSum";
import InfoTip from "./InfoTip";

export default {
  name: "AnalyticsRow",
  components: {InfoTip, OrdersSum},
  props: ['row', 'opened', 'instrument', 'googleAuth', 'yandexAuth', 'facebookAuth', 'padding', 'vkAuth'],
}
</script>
